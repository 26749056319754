import logo from './logo.svg';
import {React,useState} from 'react';
import './App.css';
import Todo from './Components/Todo/Todo';

function App(){
return (
  <div>
    <Todo/>
  </div>
)
}
export default App;

