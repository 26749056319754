import {React,useState} from 'react'
import './Todo.css'
const Todo = () => {
    const [todo, setTodo] = useState("");
    const [todos, setTodos] = useState([]);
    const [editId, setEditId] = useState(0); 
    
    const handleSubmit = (e)=>
    {
        e.preventDefault();
        if(editId)
        {
            const editTodo = todos.find((i) => i.id ===editId);
            const updated = todos.map((t) =>
            t.id === editTodo.id ?
            (t={id: t.id, todo}):
            {id: t.id, todo: t.todo});
            
            setTodos(updated);
            setEditId(0);
            setTodo("");
            return;
        }
        if(todo!== '')
        {
            setTodos([{id: `${Date.now()}` , todo}, ...todos])
        }
            setTodo("");
    }
    const handleDelete= (id) =>
    {
      const delTodo = todos.filter ((td) => td.id !== id)
      setTodos(delTodo)
    }
    
    const handleEdit = (id) => 
    {
        const edit = todos.find((i)=> i.id === id);
        setTodo(edit.todo);
        setEditId(id);
    }
    
      return (
        <div className='App'>
            <div className="container">
              <h1 className='heading'>To-Do-Aplication</h1>
              <form className='todoForm' action="" onSubmit={handleSubmit}>
                <input value={todo} onChange={(e) => setTodo(e.target.value)} type="text" />
                <button type='submit'>{editId?"Add": "Add"}</button>
              </form>
            <ul className='alltodos'>
              {
                todos.map((t) => (
                  <li className='singletodo'><span className='todotext' key={t.id}>{t.todo}</span>
                  <button onClick={()=> handleEdit(t.id)}>Edit</button>
                  <button onClick={() => handleDelete(t.id)}>Delete</button>
                  </li>
                ))
              }
    
    
            </ul>
    
            </div>
        </div>
      )
    
}

export default Todo